import { HERO_OFFER_NAME, SUBSCRIPTIONS_OPTIONS_NAME } from 'utils/constants';

/**
 * Retrieves tracking data from barrier page components and organizes it into an array of objects.
 * @returns {Array<Object>} Returns an array of objects containing component data.
 */
// TODO: Check with Analytics if these properties are still needed for tracking.
// If so, update the function to include the new components?
const getTrackingBarrierComponents = () => {
	try {
		const barrierPage = document.getElementById('barrier-page');
		// Get all components
		const components = barrierPage.querySelectorAll('[data-component]');

		const componentDataArray = [];

		// Get specific tracking data from each type of component and build the tracking object
		for (const componentNode of components) {
			const componentData = {};

			const templateName = componentNode.getAttribute('data-component');
			const componentUniqueName =
				componentNode.getAttribute('data-component-unique-name') || 'unknown';

			componentData.component_unique_name = componentUniqueName;
			componentData.component_name = `${templateName}-${componentUniqueName}`;

			switch (templateName) {
				case HERO_OFFER_NAME:
					componentData.charge_button_text =
						componentNode.querySelector(`#charge-button`).innerText || undefined;

					if (templateName === HERO_OFFER_NAME) {
						// If theme is not set, it defaults to 'light'
						const theme = componentNode.getAttribute('data-theme') || 'light';
						componentData.theme = theme;
						componentData.component_name = `${templateName}-${theme}-${componentUniqueName}`;
					}
					break;

				case SUBSCRIPTIONS_OPTIONS_NAME:
					const title =
						componentNode.querySelector(`#title-${componentUniqueName}`)?.innerText || undefined;
					componentData.title = title;

					const buttonsString = [1, 2, 3].reduce((acc, i) => {
						// Buttons' text in SubscriptionOptions can be empty string
						const buttonText =
							componentNode.querySelector(`#button${i}-${componentUniqueName}`)?.innerText || undefined;
						// Add the text of the current button to the componentData object. Eg., 'componentData.button1_text='
						componentData[`button${i}_text`] = buttonText;
						// If the current button exists, add its number and text to the accumulator
						return buttonText ? acc + `-${i}-${buttonText}` : acc;
					}, '');

					componentData.component_name = `${templateName}-${title}${buttonsString}-${componentUniqueName}`;
					break;
			}

			componentDataArray.push(componentData);
		}

		return componentDataArray;
	} catch (e) {
		return 'getTrackingBarrierComponentsError';
	}
};

export { getTrackingBarrierComponents };
