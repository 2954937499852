// Component names
export const ELECTIONS_FEATURES_NAME = 'electionsFeatures';
export const ELECTIONS_HEADER_NAME = 'electionsHeader';
export const HERO_OFFER_NAME = 'heroOffer';
export const RECOMMENDED_OFFERS_NAME = 'recommendedOffers';
export const SUBSCRIPTIONS_OPTIONS_NAME = 'subscriptionOptions';
export const TOPIC_HERO_OFFER_NAME = 'topicHeroOffer';
export const WHY_FT_NAME = 'whyFT';

// Barrier name
export const CONCURRENCY_BARRIER_NAME = 'concurrency-barrier';
export const CORPORATE_BARRIER_NAME = 'corporate-barrier';
export const GOOGLE_EXTENDED_ACCESS_BARRIER_NAME = 'google-extended-access-barrier';

/* Screen sizes
 * Copied from origami o-grid layout sizes
 * https://registry.origami.ft.com/components/o-grid@6.1.7/readme#layout-sizes
 * */
export const mediumScreenSize = '740px';
export const largeScreenSize = '980px';
export const extraLargeScreenSize = '1220px';
export const ultraLargeScreenSize = '1440px';
export const ultraExtraLargeScreenSize = '1920px';

export const DENIED_ACCESS_DECISION_POLICY_REGEX = new RegExp(/^DENIED_(SUBX|ZEPHR)_.*$/);

export const DEFAULT_ARTICLE_IMAGE_URL =
	'https://barrier-page-components.s3.eu-west-1.amazonaws.com/assets/images/banner_bg_1800x800px_why_the_ft.jpg';
